.WithPeopleMain{
    background:white;
    height:100%;
    width:100%;
    min-height: 100svh;
    min-width: 100svw;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
}

.topBlock{
    background: #f3f8fc;
    height:140px;
    width:100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.listOfAvatar{
    position: absolute;
    left: 50%;
    bottom: -30px;
    transform: translateX(-50%);
    width:fit-content;

}

.MuiInputBase-input{
    padding:8px 14px;
}

.MuiInputBase-root{
    border-radius: 24px !important;

}