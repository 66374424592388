@import url('https://fonts.googleapis.com/css?family=Noto+Sans');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fjord+One&family=Galada&display=swap');

.App {
    /* text-align: center; */
    font-family: 'Inconsolata', monospace;
    font-size: 1.4rem;
    /* letter-spacing: 0.1rem; */
    font-weight: 600;
    background-color: #272727;
    height:100%;
    min-height: 100vh;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

body::-webkit-scrollbar {
    background-color: #272727;
    width: 16px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
    background-color: #272727;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #272727;
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
    display:none;
}

.App-header {
    background-color: #272727;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.justifyC {
    display: flex;
    justify-content: center;
}
.justifyS {
    display: flex;
    justify-content: start;
}
.justifyE {
    display: flex;
    justify-content: end;
}

.alignC {
    display: flex;
    align-items: center;
}
.alignS {
    display: flex;
    align-items: start;
}
.alignE {
    display: flex;
    align-items: end;
}

.fullCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-white{
    color: #fff;
}

.text-gray{
    color:#9ba3c7;
}

.row {
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
}

.fullHeight {
    height: 100%;
}

.fullViewHeight {
    height: 100vh;
}

.fullViewWidth {
    width: 100vw;
}

.fullWidth {
    width: 100%;
}

.halfWidth {
    width: 50%;
}

@media screen and (min-width: 701px) {
    .rowDiff {
        display: flex;
        flex-direction: row;
    }
    .columnDiff {
        display: flex;
        flex-direction: column;
    }
    .fullWidthM {
        width: 100%;
    }
    .halfWidthM {
        width: 50%;
    }
    .mobileShow {
        display: none;
    }
}

@media screen and (max-width: 700px) {
    .rowDiff {
        display: flex;
        flex-direction: column;
    }
    .columnDiff {
        display: flex;
        flex-direction: row;
    }
    .fullWidthM {
        width: 80%;
    }
    .halfWidthM {
        width: 100%;
    }
    .mobileHide {
        display: none;
    }
}

/* tablet */
@media screen and (min-width: 1001px) {
    .loginRightDisplay {
        width: 50%;
    }
    .circleGlass {
        width: 400px;
        height: 400px;
    }
}
@media screen and (max-width: 1000px) {
    .loginRightDisplay {
        width: 80%;
    }
    .circleGlass {
        width: 350px;
        height: 350px;
    }
}

.circleGlass {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    margin-top: 4em;
    background-image: linear-gradient(
        to bottom right,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0)
    );
    backdrop-filter: 'blur(10px)';
    box-shadow: 4px 4px 4px rgba(30, 30, 30, 0.5);
}

.whiteText {
    color: white;
    font-size: 0.8em;
    font-family: 'Montserrat', sans-serif;
}

.loginMainText {
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5em;
    font-weight: 600;
    color: white;
}

.loginSubText {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8em;
    color: #8c8995;
}

.purpleText {
    color: #714fa5;
}

.blackBg {
    background-color: black;
}

.paddingB12 {
    padding-bottom: 12px;
}

.marginLeft8 {
    margin-left: 8px;
}

.marginRight8 {
    margin-right: 8px;
}

.cursorP {
    cursor: pointer;
}
