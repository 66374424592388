.CPExam{
	font-family: system-ui;
}

.timebar {
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 30s ease infinite;
	height: 100vh;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.questionBank{
	color: #d6d6d6;
}

.question{
	margin-bottom: 48px;
	font-size: 0.95em;
}

.answer{
	cursor: pointer;
	padding: 8px 16px;
	margin-bottom: 8px;
	border-radius: 12px;
	border:2px solid #244566;
	font-size: 0.8em;
	justify-content: space-between;
}

.playMode{
	height: 150px;
	width: 150px;
	border-radius: 50%;
	display: flex;
	text-align:center;
	justify-content: center;
	align-items: center;
	border:1px solid white;
	cursor: pointer;
}

.playMode:hover{
	background-color: #244566;
}