.LandingPage{
    display: flex;
    flex-direction: column;
    /* padding: 24px 36px; */
}

.blue{
    color: #5AB7D4;
}
.white{
    color: #fff;
}
.grey{
    color: #bbbbbb;
}

/* ///////////////////////////////////// */
.LandingPage {
    height: 100%;
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    overflow: hidden;
}
  
.star{
    position: absolute;
    background: #858585;
    border-radius: 50px;
    animation: move;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.type-1{
    width: 1px;
    height: 1px;
}

.type-2{
    width: 2px;
    height: 2px;
}

.type-3{
    width: 3px;
    height: 3px;
}

 @keyframes move {
    0%{ transform: translateY(-20vh); }
    100%{ transform: translateY(0px); }
}

